.companyName {
    color: white;
}

.date {
    font-size: 0.66em;
    color: #ffffff88;
}

.selectedStageLogo {
    margin-bottom: 25px;
}

.selectedStageSubText {
    width: 100%;
    font-size: 0.5em;
    text-align: center;
    margin: 10px;
    font-weight: normal;
}
.selectedStageSubTextRole {
    font-style: italic;
}

.sectionTimeline {
    display: block;
    text-align: start;
    text-shadow: none;
}

.timelineMainDiv {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    align-items: center;
}

.desktopTimeline {
    height: 100%;
    opacity: 0.5s;
}

.desktopTimelineChange {
    opacity: 1;
    transition: all 1s;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 50%;
}

.timelineTitle {
    width: 100%;
    height: 10%;
    font-size: 5vw;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.timelineContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    gap: 50px;
}

.timeline-stage {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 50%;
}

.timelineDownload {
    widows: 100%;
}

.divTimeline {
    height: 10vh;
}

.divSelectedStage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: -1px 5px #6f6f6f;
    background: radial-gradient(#7b8093, #3f424bdd);
    border: solid black 3px;
    width: 75%;
    height: 75%;
    border-radius: 25px;
    padding: 50px;
}

.divSelectedStageTextContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 5vh;
}

.runAnimation {
    animation: fadeInAnimationTimeline ease 1s;
    animation-iteration-count: 1;
}

@keyframes fadeInAnimationTimeline {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.timeline {
    box-sizing: border-box;
    position: relative;
    line-height: 1;
    height: 100%;
}
.timeline *,
.timeline *::before,
.timeline *::after {
    box-sizing: inherit;
}
.timeline:not(.timeline--horizontal)::before {
    background-color: #ddd;
    bottom: 0;
    content: '';
    left: 50%;
    margin-left: -2px;
    position: absolute;
    top: 0;
    width: 4px;
    z-index: 1;
}
.timeline__items {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
}
.timeline__wrap {
    overflow: hidden;
    position: relative;
    z-index: 2;
    height: 100%;
}
.timeline__item {
    font-size: 1rem;
    padding: 10px 40px 10px 0;
    position: relative;
    width: 50%;
    z-index: 2;
    cursor: pointer;
}
.timeline__item::after {
    background-color: #fff;
    border: 4px solid #ddd;
    border-radius: 50%;
    content: '';
    height: 20px;
    position: absolute;
    right: -10px;
    transform: translateY(-50%);
    top: 50%;
    width: 20px;
    z-index: 1;
}
.timeline__selected::after {
    background-color: gold;
    border: 4px solid #a48a00;
}

.timeline__item.animated {
    animation-duration: 1s;
    animation-fill-mode: both;
    opacity: 0;
}
.timeline__item.fadeIn {
    animation-name: fadeIn;
}
.timeline__item--left {
    left: 0;
}
.timeline__item--right {
    left: 50%;
    padding: 10px 0 10px 40px;
}
.timeline__item--right::after {
    left: -10px;
}
.timeline__item--right .timeline__content::before {
    border-bottom: 10px solid transparent;
    border-right: 12px solid #ccc;
    border-left: none;
    border-top: 10px solid transparent;
    left: -12px;
}
.timeline__item--right .timeline__content::after {
    border-bottom: 9px solid transparent;
    border-right: 11px solid #fff;
    border-left: none;
    border-top: 9px solid transparent;
    left: -10px;
}
.timeline__content {
    font-size: 2em;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 15px;
    color: #333;
    display: block;
    padding: 10px;
    position: relative;
    border: 2px solid transparent;

    display: flex;
    flex-direction: column;
}
.timeline__active {
    border: solid 2px gold;
}
.timeline__content:active {
    border: solid 2px gold;
}
.timeline__content::selection {
    border: solid 2px gold;
}
.timeline__content:hover {
    border: solid 2px gold;
    transition: border 0.2s ease-in;
}
.timeline__content::before,
.timeline__content::after {
    content: '';
    height: 0;
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    width: 0;
}
.timeline__content::before {
    border-bottom: 10px solid transparent;
    border-left: 12px solid #ccc;
    border-top: 10px solid transparent;
    right: -12px;
    z-index: 1;
}
.timeline__content::after {
    border-bottom: 9px solid transparent;
    border-left: 11px solid #fff;
    border-top: 9px solid transparent;
    right: -10px;
    z-index: 2;
}
.timeline__content h2 {
    font-size: 20px;
    font-weight: 700;
    margin: 0 0 10px;
}
.timeline__content p {
    font-size: 15px;
    line-height: 1.5;
    margin-bottom: 10px;
}

.timeline--mobile {
    padding: 0;
}
.timeline--mobile::before {
    left: 10px !important;
    margin: 0 !important;
}
.timeline--mobile .timeline__item {
    left: 0;
    padding-left: 40px;
    width: 100%;
}
.timeline--mobile .timeline__item::after {
    left: 2px;
    margin: 0;
}
.timeline--mobile .timeline__item .timeline__content::before {
    left: -12px;
    border-bottom: 12px solid transparent;
    border-right: 12px solid #ccc;
    border-left: none;
    border-top: 12px solid transparent;
}
.timeline--mobile .timeline__item .timeline__content::after {
    left: -10px;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #fff;
    border-left: none;
    border-top: 10px solid transparent;
}
@keyframes fadeIn {
    0% {
        opacity: 0;
        top: 70px;
    }
    100% {
        opacity: 1;
        top: 0px;
    }
}
@keyframes liftUp {
    0% {
        top: 0px;
    }
    100% {
        top: -15px;
    }
}

.color-spotify {
    background-color: #1ed760;
}

.color-amazon {
    background-color: #ff9a00;
}

.color-king {
    background-color: #ff5c01;
}

.color-future-lighthouse {
    background-color: #193a53;
}

.color-gameloft {
    background-color: #8e9294;
}

.color-EA {
    background-color: #fc4444;
}

.color-carto {
    background-color: #142c44;
}

.color-complutense {
    background-color: #9a8a43;
}

.color-simfor {
    background-color: #3783e5;
}

.color-granada {
    background-color: #bb2c2c;
}

.color-mediapost {
    background-color: #dc2c34;
}

.color-UC3M {
    background-color: #000e77;
}

@media all and (max-width: 1400px) {
    .selectedStageSubText {
        font-size: 0.44em;
        line-height: 1;
        margin: 5px;
    }
    .divSelectedStage {
        height: 63%;
        padding: 25px;
        display: flex;
        margin: 2px;
    }
    .timeline__content {
        font-size: 0.75em;
    }
    .timeline__item {
        font-size: 1rem;
        padding: 2px 10px 2px 0;
        position: relative;
        width: 50%;
        z-index: 2;
        cursor: pointer;
    }
    .timelineContainer {
        gap: 25px;
    }
}

@media all and (max-width: 667px) {
    .timeline--mobile .timeline__item {
        left: 0;
        padding-left: 30px;
        width: 100%;
    }

    .timeline__item {
        font-size: 1rem;
        padding: 2px 5px 2px 0;
    }

    .timeline__content {
        font-size: 1em;
        padding: 3px;
    }

    .timeline-stage {
        justify-content: center;
    }

    .divSelectedStage {
        padding: 5px;
        width: 100%;
    }
    .timelineContainer {
        gap: 3px;
        height: 87%;
    }

    .selectedStageLogo {
        width: 75%;
        height: 75%;
    }

    .selectedStageSubText {
        font-size: 0.35em;
        line-height: 1.1;
        margin: 4px;
    }

    .divSelectedStage {
        border-width: 2px;
    }
}

@media all and (max-width: 1200px) {
    .divSelectedStage {
        width: 90%;
    }
}

@media only screen and (max-device-width: 1024px) and (orientation: landscape) {
    .selectedStageLogo {
        max-height: 100px;
        max-width: 100px;
        margin-bottom: 10px;
    }
    .timeline__item {
        font-size: 0.6rem;
        padding: 0px 1px 0px 0;
    }

    .timeline__content {
        font-size: 1em;
        padding: 2px;
    }

    .timeline-stage {
        justify-content: center;
    }
}

@media all and (min-height: 800px) and (max-height: 1400px) {
    .date {
        font-size: 0.66em;
        color: #ffffff88;
    }
    .timeline__content {
        font-size: 0.65em;
    }
    .timeline__item {
        font-size: 1.7rem;
        padding: 3px 10px 3px 0;
    }
}

@media all and (min-height: 575px) and (max-height: 800px) {
    .timeline__content {
        font-size: 1.2em;
        padding: 5px;
    }
    .timeline__item {
        font-size: 0.6rem;
        padding: 3px 10px 3px 0;
    }
}

@media all and (max-height: 575px) {
    .timeline__content {
        font-size: 0.75em;
        padding: 3px;
    }
    .timeline__item {
        font-size: 0.5rem;
        padding: 1px 10px 1px 0;
    }
}

@media all and (max-height: 575px) {
    .timelineTitle {
        font-size: 5vh;
    }
}

@media all and (max-width: 768px) {
    .timelineTitle {
        height: 5%;
    }
}
