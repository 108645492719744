* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    max-width: 100%;
    overflow-x: clip;
}

body {
    height: 100%;
    min-height: 100vh;
    min-height: -webkit-fill-available;
}
html {
    height: -webkit-fill-available;
}

.hide {
    visibility: hidden;
}

.skip-link {
    position: absolute;
    top: -40px;
    left: 0;
    background: #000000;
    color: white;
    padding: 8px;
    z-index: 10;
}

.skip-link:focus {
    top: 0;
}

.close-button {
    width: 20px;
    height: 20px;
    background-color: #777;
    border: none;
    color: white;
    border-radius: 20px;
    cursor: pointer;
}

.hidden {
    display: none !important;
}

body {
    scroll-behavior: smooth;
    font-family: 'Raleway', sans-serif;
    background-color: black;
}

.wrapper {
    justify-content: center;
    scroll-snap-type: y mandatory;
    overflow-y: scroll;
    height: 100vh;

    align-items: center;
    justify-content: center;
}

.section {
    text-align: center;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    scroll-snap-align: start;
    color: #fff;
    font-size: 5vw;
    text-shadow: 0px 2px 0px #b2a98f, 0px 4px 3px rgba(0, 0, 0, 0.15),
        0px 8px 1px rgba(0, 0, 0, 0.1);
}

.bg-home {
    background-image: linear-gradient(#202124, #262626);
}

.bg-second-screen {
    background-image: linear-gradient(#262626, #41434a);
}

.bg-third-screen {
    background-image: linear-gradient(#41434a, #686c78);
}

.bg-fourth-screen {
    background-image: linear-gradient(#686c78, #8d93a6);
}

.bg-fifth-screen {
    background-image: linear-gradient(#8d93a6, #b4bcd4);
}

.bg-sixth-screen {
    background-image: linear-gradient(#b4bcd4, #c0c9e4);
}

.interests-gif {
    text-decoration-line: underline;
    text-decoration-style: dotted;
    cursor: grab;
}

.main-text-section {
    font-size: 5vw;
    margin: 50px;
}

.text-second-screen {
    margin-bottom: 100px;
}

.products {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
}

.products-title {
    height: 10%;
    font-size: 5vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.desktopProductsContent {
    height: 90%;
}

.mobileProductsContent {
    height: 90%;
}

.products-grid {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 100%;
    justify-content: space-around;
}

.row {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    display: -webkit-flex;
    -webkit-flex-wrap: wrap;
    -webkit-flex-direction: row;
    justify-content: flex-start;
    height: 50%;
    align-content: center;
}

.rowProductsMobile {
    justify-content: center;
    height: 20%;
    width: 100%;
    align-content: center;
}

.col-md-4 {
    flex: 0 0 50%;
    -webkit-flex: 0 0 50%;
    text-align: center;
    vertical-align: middle;
    font-size: 40px;
}

.col-md-3 {
    flex: 0 0 33.33%;
    -webkit-flex: 0 0 33.33%;
    text-align: center;
    vertical-align: middle;
    font-size: 40px;
}

.cell {
    height: 100%;
    width: 50%;
}

.img-product {
    border: 2px solid white;
    width: 100%;
    height: 100%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.hover-rotate {
    overflow: hidden;
    width: 100%;
}

.hover-rotate img {
    transition: all 0.3s;
    box-sizing: border-box;
    max-width: 100%;
}

.hover-rotate:hover img {
    transform: scale(1.3) rotate(5deg);
}

.cookie-banner {
    font-size: 20px;
    position: fixed;
    bottom: 40px;
    left: 10%;
    right: 10%;
    padding: 5px 14px;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #eee;
    border-radius: 5px;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
    color: #404040;
    text-align: center;
}

.img-product-kindle {
    background: url(../images/webp/kindleCover.webp) center / cover;
}

.img-product-candy {
    background: url(../images/webp/candycrush.webp) center / cover;
}

.img-product-spotify {
    background: url(../images/webp/spotifyCover.webp) center / cover;
}

.img-product-ea {
    background: url(../images/webp/electronicArts.webp) center / cover;
}

.img-product-simfor {
    background: url(../images/webp/simforSim.webp) center / cover;
}

.image-caption {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    cursor: crosshair;
    width: 100%;
    height: 104%;
    top: -104%;
    background: rgba(0, 0, 0, 0.7);
    position: relative;
    opacity: 0;
    transition: all 300ms ease-in-out;
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    -ms-transition: all 300ms ease-in-out;
}

.image-caption h5 {
    padding: 45px 0px 5px 0px;
    text-align: center;
    margin-left: -15px;
    text-transform: uppercase;
    font-size: 50px;
    color: #00bfb6;
}

.image-caption p {
    text-align: center;
    font-family: 'Overpass Mono', monospace;
    color: #fff;
    width: 90%;
    gap: 1.33%;
}

.image-caption:hover {
    opacity: 1;
}

.main-pings {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    font-size: 3.5vw;
}

.div-pet-projects {
    width: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.link-products {
    text-decoration: underline;
    cursor: pointer;
    color: inherit;
}

.end-component {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.end-component-title {
    height: 25%;
}

.end-component-bell {
    display: flex;
    height: 25%;
    justify-content: center;
    gap: 30px;
}

.end-component-center {
    height: 50%;
    gap: 3.5%;
    vertical-align: middle;
}

.main-pings-bolder-text {
    font-weight: bolder;
}

.pet-project-link {
    color: #e4e4e4;
    display: inline-block;
}

.remove-decorations {
    text-decoration: none;
    width: 50%;
}

.home-image-img {
    clip-path: circle(200px at center);
    -webkit-clip-path: circle(200px at center);
    animation-iteration-count: 1;
    animation-name: home-image-animation;
    animation-duration: 1s;
    transform-origin: 70% 70%;
    display: inline-block;
    cursor: grab;
    margin-top: 75px;
    width: 400px;
    height: 400px;

    background: url(../images/webp/cartoonHRwebp.webp) center / cover;
}

@keyframes home-image-animation {
    0% {
        clip-path: circle(18px at center);
        -webkit-clip-path: circle(18px at center);
    }
    100% {
        clip-path: circle(200px at center);
        -webkit-clip-path: circle(200px at center);
    }
}

.wave {
    cursor: grab;
    animation-iteration-count: 1;
    animation-name: wave-animation;
    animation-duration: 2.75s;
    transform-origin: 70% 70%;
    display: inline-block;
}

@keyframes wave-animation {
    0% {
        transform: rotate(0deg);
    }
    10% {
        transform: rotate(14deg);
    }
    20% {
        transform: rotate(-8deg);
    }
    30% {
        transform: rotate(14deg);
    }
    40% {
        transform: rotate(-4deg);
    }
    50% {
        transform: rotate(10deg);
    }
    60% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

.divResume {
    padding: 40px;
}

.imgResume {
    max-width: 150px;
    margin: 30px;
}

.transparent {
    opacity: 0;
}

@keyframes fadeInAnimationSection {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.fadeInSection {
    animation: fadeInAnimationSection 0.45s;
    animation-fill-mode: forwards;
}

@media all and (min-width: 769px) {
    .home-image-img {
        background: url(../images/webp/cartoon.webp) center / cover;
    }

    .desktopProductsContent {
        display: block;
    }
    .mobileProductsContent {
        display: none;
    }
}

@media all and (min-width: 769px) and (max-width: 1200px) {
    .image-caption h5 {
        font-size: 4vw;
    }

    .image-caption p {
        font-size: 3vw;
    }
}

@media all and (max-width: 768px) {
    .desktopProductsContent {
        display: none;
    }
    .mobileProductsContent {
        display: block;
    }

    .mobileProductsContent {
        height: 87%;
    }

    .products-title {
        height: 5%;
    }

    .image-caption {
        top: -110%;
        height: 108%;
    }

    .image-caption h5 {
        padding: 25px 0px 2px 0px;
        margin-left: 0;
        text-transform: uppercase;
        font-size: 2rem;
        font-size: 25px;
    }

    .image-caption p {
        font-size: 1.5rem;
    }

    .home-image-img {
        clip-path: circle(100px at center);
        -webkit-clip-path: circle(100px at center);
        width: 200px;
        height: 200px;
    }

    @keyframes home-image-animation {
        0% {
            clip-path: circle(18px at center);
            -webkit-clip-path: circle(18px at center);
        }
        100% {
            clip-path: circle(100px at center);
            -webkit-clip-path: circle(100px at center);
        }
    }

    .end-component-title {
        justify-content: end;
    }

    .end-component-bell {
        justify-content: start;
    }
}

@media only screen and (min-device-width: 600px) and (max-device-width: 1250px) and (orientation: landscape) {
    .home-image-img {
        margin-top: 35px;
        clip-path: circle(100px at center);
        -webkit-clip-path: circle(100px at center);
        width: 200px;
        height: 200px;
    }

    @keyframes home-image-animation {
        0% {
            clip-path: circle(18px at center);
            -webkit-clip-path: circle(18px at center);
        }
        100% {
            clip-path: circle(100px at center);
            -webkit-clip-path: circle(100px at center);
        }
    }
}

@media all and (max-width: 632px) {
    .cookie-banner {
        left: 4%;
        right: 4%;
        padding: 5px;
    }
    .close-button {
        width: 30px;
    }

    .section {
        font-size: 5vw;
        line-height: 1.75em;
        text-shadow: none;
    }

    .main-text-section {
        font-size: 5vw;
        line-height: 1.75em;
    }

    .col-md-3 {
        font-size: 20px;
    }

    .col-md-4 {
        font-size: 20px;
    }

    .end-component {
        gap: 40px;
    }

    .main-pings {
        line-height: 2rem;
    }

    .end-component {
        gap: 10px;
    }

    .image-caption {
        height: 108%;
        top: -113%;
    }

    .image-caption h5 {
        padding: 0;
        margin-left: 0;
        text-transform: uppercase;
        font-size: 4vw;
        font-size: 25px;
    }

    .image-caption p {
        margin-left: 10px;
        margin-right: 10px;
        font-size: 3vw;
        line-height: 1rem;
    }
}

@media all and (max-height: 500px) {
    .section {
        font-size: 5vh;
    }
    .main-text-section {
        font-size: 5vh;
    }
    .products-title {
        font-size: 5vh;
    }
    .main-pings {
        font-size: 5vh;
    }
}
