* {
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
  max-width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: clip;
}

body {
  height: 100%;
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

html {
  height: -webkit-fill-available;
}

.hide {
  visibility: hidden;
}

.skip-link {
  color: #fff;
  z-index: 10;
  background: #000;
  padding: 8px;
  position: absolute;
  top: -40px;
  left: 0;
}

.skip-link:focus {
  top: 0;
}

.close-button {
  width: 20px;
  height: 20px;
  color: #fff;
  cursor: pointer;
  background-color: #777;
  border: none;
  border-radius: 20px;
}

.hidden {
  display: none !important;
}

body {
  scroll-behavior: smooth;
  background-color: #000;
  font-family: Raleway, sans-serif;
}

.wrapper {
  scroll-snap-type: y mandatory;
  height: 100vh;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
}

.section {
  text-align: center;
  height: 100vh;
  scroll-snap-align: start;
  color: #fff;
  text-shadow: 0 2px #b2a98f, 0 4px 3px #00000026, 0 8px 1px #0000001a;
  justify-content: center;
  align-items: center;
  font-size: 5vw;
  display: flex;
}

.bg-home {
  background-image: linear-gradient(#202124, #262626);
}

.bg-second-screen {
  background-image: linear-gradient(#262626, #41434a);
}

.bg-third-screen {
  background-image: linear-gradient(#41434a, #686c78);
}

.bg-fourth-screen {
  background-image: linear-gradient(#686c78, #8d93a6);
}

.bg-fifth-screen {
  background-image: linear-gradient(#8d93a6, #b4bcd4);
}

.bg-sixth-screen {
  background-image: linear-gradient(#b4bcd4, #c0c9e4);
}

.interests-gif {
  cursor: grab;
  text-decoration-line: underline;
  text-decoration-style: dotted;
}

.main-text-section {
  margin: 50px;
  font-size: 5vw;
}

.text-second-screen {
  margin-bottom: 100px;
}

.products {
  height: 100%;
  width: 100%;
  flex-direction: column;
  display: flex;
}

.products-title {
  height: 10%;
  justify-content: center;
  align-items: center;
  font-size: 5vw;
  display: flex;
}

.desktopProductsContent, .mobileProductsContent {
  height: 90%;
}

.products-grid {
  height: 100%;
  flex-flow: column wrap;
  justify-content: space-around;
  display: flex;
}

.row {
  height: 50%;
  flex-flow: wrap;
  place-content: center flex-start;
  display: -webkit-flex;
}

.rowProductsMobile {
  height: 20%;
  width: 100%;
  place-content: center;
}

.col-md-4 {
  text-align: center;
  vertical-align: middle;
  flex: 0 0 50%;
  font-size: 40px;
}

.col-md-3 {
  text-align: center;
  vertical-align: middle;
  flex: 0 0 33.33%;
  font-size: 40px;
}

.cell {
  height: 100%;
  width: 50%;
}

.img-product {
  width: 100%;
  height: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  border: 2px solid #fff;
}

.hover-rotate {
  width: 100%;
  overflow: hidden;
}

.hover-rotate img {
  box-sizing: border-box;
  max-width: 100%;
  transition: all .3s;
}

.hover-rotate:hover img {
  transform: scale(1.3)rotate(5deg);
}

.cookie-banner {
  z-index: 100;
  color: #404040;
  text-align: center;
  background-color: #eee;
  border-radius: 5px;
  justify-content: space-between;
  align-items: center;
  padding: 5px 14px;
  font-size: 20px;
  display: flex;
  position: fixed;
  bottom: 40px;
  left: 10%;
  right: 10%;
  box-shadow: 0 0 2px 1px #0003;
}

.img-product-kindle {
  background: url("kindleCover.f48eaac4.webp") center / cover;
}

.img-product-candy {
  background: url("candycrush.78b9d31e.webp") center / cover;
}

.img-product-spotify {
  background: url("spotifyCover.81a0a3b5.webp") center / cover;
}

.img-product-ea {
  background: url("electronicArts.4df356d5.webp") center / cover;
}

.img-product-simfor {
  background: url("simforSim.058ac0e1.webp") center / cover;
}

.image-caption {
  cursor: crosshair;
  width: 100%;
  height: 104%;
  opacity: 0;
  -o-transition: all .3s ease-in-out;
  background: #000000b3;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all .3s ease-in-out;
  display: flex;
  position: relative;
  top: -104%;
}

.image-caption h5 {
  text-align: center;
  text-transform: uppercase;
  color: #00bfb6;
  margin-left: -15px;
  padding: 45px 0 5px;
  font-size: 50px;
}

.image-caption p {
  text-align: center;
  color: #fff;
  width: 90%;
  gap: 1.33%;
  font-family: Overpass Mono, monospace;
}

.image-caption:hover {
  opacity: 1;
}

.main-pings {
  height: 100%;
  width: 100%;
  flex-direction: column;
  font-size: 3.5vw;
  display: flex;
}

.div-pet-projects {
  width: 95%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.link-products {
  cursor: pointer;
  color: inherit;
  text-decoration: underline;
}

.end-component {
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.end-component-title {
  height: 25%;
}

.end-component-bell {
  height: 25%;
  justify-content: center;
  gap: 30px;
  display: flex;
}

.end-component-center {
  height: 50%;
  vertical-align: middle;
  gap: 3.5%;
}

.main-pings-bolder-text {
  font-weight: bolder;
}

.pet-project-link {
  color: #e4e4e4;
  display: inline-block;
}

.remove-decorations {
  width: 50%;
  text-decoration: none;
}

.home-image-img {
  -webkit-clip-path: circle(200px);
  clip-path: circle(200px);
  transform-origin: 70% 70%;
  cursor: grab;
  width: 400px;
  height: 400px;
  background: url("cartoonHRwebp.8bf348c6.webp") center / cover;
  margin-top: 75px;
  animation-name: home-image-animation;
  animation-duration: 1s;
  animation-iteration-count: 1;
  display: inline-block;
}

@keyframes home-image-animation {
  0% {
    -webkit-clip-path: circle(18px);
    clip-path: circle(18px);
  }

  100% {
    -webkit-clip-path: circle(200px);
    clip-path: circle(200px);
  }
}

.wave {
  cursor: grab;
  transform-origin: 70% 70%;
  animation-name: wave-animation;
  animation-duration: 2.75s;
  animation-iteration-count: 1;
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0);
  }

  10% {
    transform: rotate(14deg);
  }

  20% {
    transform: rotate(-8deg);
  }

  30% {
    transform: rotate(14deg);
  }

  40% {
    transform: rotate(-4deg);
  }

  50% {
    transform: rotate(10deg);
  }

  60% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(0);
  }
}

.divResume {
  padding: 40px;
}

.imgResume {
  max-width: 150px;
  margin: 30px;
}

.transparent {
  opacity: 0;
}

@keyframes fadeInAnimationSection {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fadeInSection {
  animation: .45s forwards fadeInAnimationSection;
}

@media (min-width: 769px) {
  .home-image-img {
    background: url("cartoon.ff91a39d.webp") center / cover;
  }

  .desktopProductsContent {
    display: block;
  }

  .mobileProductsContent {
    display: none;
  }
}

@media (min-width: 769px) and (max-width: 1200px) {
  .image-caption h5 {
    font-size: 4vw;
  }

  .image-caption p {
    font-size: 3vw;
  }
}

@media (max-width: 768px) {
  .desktopProductsContent {
    display: none;
  }

  .mobileProductsContent {
    height: 87%;
    display: block;
  }

  .products-title {
    height: 5%;
  }

  .image-caption {
    height: 108%;
    top: -110%;
  }

  .image-caption h5 {
    text-transform: uppercase;
    margin-left: 0;
    padding: 25px 0 2px;
    font-size: 25px;
  }

  .image-caption p {
    font-size: 1.5rem;
  }

  .home-image-img {
    -webkit-clip-path: circle(100px);
    clip-path: circle(100px);
    width: 200px;
    height: 200px;
  }

  @keyframes home-image-animation {
    0% {
      -webkit-clip-path: circle(18px);
      clip-path: circle(18px);
    }

    100% {
      -webkit-clip-path: circle(100px);
      clip-path: circle(100px);
    }
  }

  .end-component-title {
    justify-content: end;
  }

  .end-component-bell {
    justify-content: start;
  }
}

@media only screen and (min-device-width: 600px) and (max-device-width: 1250px) and (orientation: landscape) {
  .home-image-img {
    -webkit-clip-path: circle(100px);
    clip-path: circle(100px);
    width: 200px;
    height: 200px;
    margin-top: 35px;
  }

  @keyframes home-image-animation {
    0% {
      -webkit-clip-path: circle(18px);
      clip-path: circle(18px);
    }

    100% {
      -webkit-clip-path: circle(100px);
      clip-path: circle(100px);
    }
  }
}

@media (max-width: 632px) {
  .cookie-banner {
    padding: 5px;
    left: 4%;
    right: 4%;
  }

  .close-button {
    width: 30px;
  }

  .section {
    text-shadow: none;
    font-size: 5vw;
    line-height: 1.75em;
  }

  .main-text-section {
    font-size: 5vw;
    line-height: 1.75em;
  }

  .col-md-3, .col-md-4 {
    font-size: 20px;
  }

  .end-component {
    gap: 40px;
  }

  .main-pings {
    line-height: 2rem;
  }

  .end-component {
    gap: 10px;
  }

  .image-caption {
    height: 108%;
    top: -113%;
  }

  .image-caption h5 {
    text-transform: uppercase;
    margin-left: 0;
    padding: 0;
    font-size: 25px;
  }

  .image-caption p {
    margin-left: 10px;
    margin-right: 10px;
    font-size: 3vw;
    line-height: 1rem;
  }
}

@media (max-height: 500px) {
  .section, .main-text-section, .products-title, .main-pings {
    font-size: 5vh;
  }
}

.companyName {
  color: #fff;
}

.date {
  color: #fff8;
  font-size: .66em;
}

.selectedStageLogo {
  margin-bottom: 25px;
}

.selectedStageSubText {
  width: 100%;
  text-align: center;
  margin: 10px;
  font-size: .5em;
  font-weight: normal;
}

.selectedStageSubTextRole {
  font-style: italic;
}

.sectionTimeline {
  text-align: start;
  text-shadow: none;
  display: block;
}

.timelineMainDiv {
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.desktopTimeline {
  height: 100%;
  opacity: .5s;
}

.desktopTimelineChange {
  opacity: 1;
  width: 50%;
  justify-content: flex-end;
  align-items: center;
  transition: all 1s;
  display: flex;
}

.timelineTitle {
  width: 100%;
  height: 10%;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 5vw;
  display: flex;
}

.timelineContainer {
  width: 100%;
  height: 100%;
  flex-direction: row;
  gap: 50px;
  display: flex;
}

.timeline-stage {
  width: 50%;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.timelineDownload {
  widows: 100%;
}

.divTimeline {
  height: 10vh;
}

.divSelectedStage {
  width: 75%;
  height: 75%;
  background: radial-gradient(#7b8093, #3f424bdd);
  border: 3px solid #000;
  border-radius: 25px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
  display: flex;
  box-shadow: -1px 5px #6f6f6f;
}

.divSelectedStageTextContainer {
  flex-direction: column;
  align-items: center;
  font-size: 5vh;
  display: flex;
}

.runAnimation {
  animation: 1s fadeInAnimationTimeline;
}

@keyframes fadeInAnimationTimeline {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.timeline {
  box-sizing: border-box;
  height: 100%;
  line-height: 1;
  position: relative;
}

.timeline *, .timeline :before, .timeline :after {
  box-sizing: inherit;
}

.timeline:not(.timeline--horizontal):before {
  content: "";
  width: 4px;
  z-index: 1;
  background-color: #ddd;
  margin-left: -2px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
}

.timeline__items {
  height: 100%;
  flex-direction: column;
  justify-content: space-evenly;
  display: flex;
}

.timeline__wrap {
  z-index: 2;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.timeline__item {
  width: 50%;
  z-index: 2;
  cursor: pointer;
  padding: 10px 40px 10px 0;
  font-size: 1rem;
  position: relative;
}

.timeline__item:after {
  content: "";
  height: 20px;
  width: 20px;
  z-index: 1;
  background-color: #fff;
  border: 4px solid #ddd;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  right: -10px;
  transform: translateY(-50%);
}

.timeline__selected:after {
  background-color: gold;
  border: 4px solid #a48a00;
}

.timeline__item.animated {
  opacity: 0;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.timeline__item.fadeIn {
  animation-name: fadeIn;
}

.timeline__item--left {
  left: 0;
}

.timeline__item--right {
  padding: 10px 0 10px 40px;
  left: 50%;
}

.timeline__item--right:after {
  left: -10px;
}

.timeline__item--right .timeline__content:before {
  border: 10px solid #0000;
  border-left: none;
  border-right: 12px solid #ccc;
  left: -12px;
}

.timeline__item--right .timeline__content:after {
  border: 9px solid #0000;
  border-left: none;
  border-right: 11px solid #fff;
  left: -10px;
}

.timeline__content {
  color: #333;
  background-color: #fff;
  border: 2px solid #0000;
  border-radius: 15px;
  flex-direction: column;
  padding: 10px;
  font-size: 2em;
  display: flex;
  position: relative;
}

.timeline__active, .timeline__content:active {
  border: 2px solid gold;
}

.timeline__content::selection {
  border: 2px solid gold;
}

.timeline__content:hover {
  border: 2px solid gold;
  transition: border .2s ease-in;
}

.timeline__content:before, .timeline__content:after {
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.timeline__content:before {
  z-index: 1;
  border-top: 10px solid #0000;
  border-bottom: 10px solid #0000;
  border-left: 12px solid #ccc;
  right: -12px;
}

.timeline__content:after {
  z-index: 2;
  border-top: 9px solid #0000;
  border-bottom: 9px solid #0000;
  border-left: 11px solid #fff;
  right: -10px;
}

.timeline__content h2 {
  margin: 0 0 10px;
  font-size: 20px;
  font-weight: 700;
}

.timeline__content p {
  margin-bottom: 10px;
  font-size: 15px;
  line-height: 1.5;
}

.timeline--mobile {
  padding: 0;
}

.timeline--mobile:before {
  margin: 0 !important;
  left: 10px !important;
}

.timeline--mobile .timeline__item {
  width: 100%;
  padding-left: 40px;
  left: 0;
}

.timeline--mobile .timeline__item:after {
  margin: 0;
  left: 2px;
}

.timeline--mobile .timeline__item .timeline__content:before {
  border: 12px solid #0000;
  border-left: none;
  border-right-color: #ccc;
  left: -12px;
}

.timeline--mobile .timeline__item .timeline__content:after {
  border: 10px solid #0000;
  border-left: none;
  border-right-color: #fff;
  left: -10px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    top: 70px;
  }

  100% {
    opacity: 1;
    top: 0;
  }
}

@keyframes liftUp {
  0% {
    top: 0;
  }

  100% {
    top: -15px;
  }
}

.color-spotify {
  background-color: #1ed760;
}

.color-amazon {
  background-color: #ff9a00;
}

.color-king {
  background-color: #ff5c01;
}

.color-future-lighthouse {
  background-color: #193a53;
}

.color-gameloft {
  background-color: #8e9294;
}

.color-EA {
  background-color: #fc4444;
}

.color-carto {
  background-color: #142c44;
}

.color-complutense {
  background-color: #9a8a43;
}

.color-simfor {
  background-color: #3783e5;
}

.color-granada {
  background-color: #bb2c2c;
}

.color-mediapost {
  background-color: #dc2c34;
}

.color-UC3M {
  background-color: #000e77;
}

@media (max-width: 1400px) {
  .selectedStageSubText {
    margin: 5px;
    font-size: .44em;
    line-height: 1;
  }

  .divSelectedStage {
    height: 63%;
    margin: 2px;
    padding: 25px;
    display: flex;
  }

  .timeline__content {
    font-size: .75em;
  }

  .timeline__item {
    width: 50%;
    z-index: 2;
    cursor: pointer;
    padding: 2px 10px 2px 0;
    font-size: 1rem;
    position: relative;
  }

  .timelineContainer {
    gap: 25px;
  }
}

@media (max-width: 667px) {
  .timeline--mobile .timeline__item {
    width: 100%;
    padding-left: 30px;
    left: 0;
  }

  .timeline__item {
    padding: 2px 5px 2px 0;
    font-size: 1rem;
  }

  .timeline__content {
    padding: 3px;
    font-size: 1em;
  }

  .timeline-stage {
    justify-content: center;
  }

  .divSelectedStage {
    width: 100%;
    padding: 5px;
  }

  .timelineContainer {
    height: 87%;
    gap: 3px;
  }

  .selectedStageLogo {
    width: 75%;
    height: 75%;
  }

  .selectedStageSubText {
    margin: 4px;
    font-size: .35em;
    line-height: 1.1;
  }

  .divSelectedStage {
    border-width: 2px;
  }
}

@media (max-width: 1200px) {
  .divSelectedStage {
    width: 90%;
  }
}

@media only screen and (max-device-width: 1024px) and (orientation: landscape) {
  .selectedStageLogo {
    max-height: 100px;
    max-width: 100px;
    margin-bottom: 10px;
  }

  .timeline__item {
    padding: 0 1px 0 0;
    font-size: .6rem;
  }

  .timeline__content {
    padding: 2px;
    font-size: 1em;
  }

  .timeline-stage {
    justify-content: center;
  }
}

@media (min-height: 800px) and (max-height: 1400px) {
  .date {
    color: #fff8;
    font-size: .66em;
  }

  .timeline__content {
    font-size: .65em;
  }

  .timeline__item {
    padding: 3px 10px 3px 0;
    font-size: 1.7rem;
  }
}

@media (min-height: 575px) and (max-height: 800px) {
  .timeline__content {
    padding: 5px;
    font-size: 1.2em;
  }

  .timeline__item {
    padding: 3px 10px 3px 0;
    font-size: .6rem;
  }
}

@media (max-height: 575px) {
  .timeline__content {
    padding: 3px;
    font-size: .75em;
  }

  .timeline__item {
    padding: 1px 10px 1px 0;
    font-size: .5rem;
  }

  .timelineTitle {
    font-size: 5vh;
  }
}

@media (max-width: 768px) {
  .timelineTitle {
    height: 5%;
  }
}

.linkSocialNetwork {
  width: 10%;
  text-align: center;
  cursor: pointer;
}

.ul-wrapper {
  flex-wrap: wrap;
  justify-content: center;
  gap: 3px;
  list-style: none;
  display: flex;
}

.wrapper-social-networker {
  width: 85%;
  justify-content: center;
  align-items: center;
  margin: 20px;
  display: flex;
  position: relative;
}

.wrapper-social-networker ul {
  list-style: none;
}

.wrapper-social-networker ul li {
  text-align: center;
  cursor: pointer;
  float: left;
  border: 5px solid #d8e2dc;
  border-radius: 50%;
  margin: 0 10px;
  padding: 25px;
  line-height: 0;
  transition: all .5s;
}

.wrapper-social-networker ul li .fa {
  color: #d8e2dc;
  transition: all .5s;
}

.wrapper-social-networker ul li:hover.linkedin {
  border: 5px solid #2867b2;
  transition: all .5s;
  box-shadow: 0 0 15px #2867b2;
}

.wrapper-social-networker ul li .fa-linkedin {
  color: #3b5998aa;
}

.wrapper-social-networker ul li:hover .fa-linkedin {
  color: #3b5998;
  text-shadow: 0 0 15px #2867b2;
  transition: all .5s;
}

.wrapper-social-networker ul li:hover.twitter {
  border: 5px solid #00aced;
  transition: all .5s;
  box-shadow: 0 0 15px #00aced;
}

.wrapper-social-networker ul li .fa-twitter {
  color: #00acedaa;
}

.wrapper-social-networker ul li:hover .fa-twitter {
  color: #00aced;
  text-shadow: 0 0 15px #00aced;
  transition: all .5s;
}

.wrapper-social-networker ul li:hover.github {
  border: 5px solid #333;
  transition: all .5s;
  box-shadow: 0 0 15px #333;
}

.wrapper-social-networker ul li .fa-github {
  color: #333a;
}

.wrapper-social-networker ul li:hover .fa-github {
  color: #333;
  text-shadow: 0 0 15px #333;
  transition: all .5s;
}

.wrapper-social-networker ul li:hover.envelope {
  border: 5px solid #4dc247;
  transition: all .5s;
  box-shadow: 0 0 15px #4dc247;
}

.wrapper-social-networker ul li .fa-envelope {
  color: #4dc247aa;
}

.wrapper-social-networker ul li:hover .fa-envelope {
  color: #4dc247;
  text-shadow: 0 0 15px #4dc247;
  transition: all .5s;
}

.wrapper-social-networker ul li:hover.rss {
  border: 5px solid #f26522;
  transition: all .5s;
  box-shadow: 0 0 15px #f26522;
}

.wrapper-social-networker ul li .fa-rss {
  color: #f26522aa;
}

.wrapper-social-networker ul li:hover .fa-rss {
  color: #f26522;
  text-shadow: 0 0 15px #f26522;
  transition: all .5s;
}

@media screen and (max-width: 640px) {
  .wrapper-social-networker {
    margin: 0;
  }

  .wrapper-social-networker ul li .fa {
    color: #d8e2dc;
    transition: all .5s;
  }
}

@media screen and (max-width: 340px) {
  .wrapper-social-networker {
    margin: 0;
  }

  .wrapper-social-networker ul li {
    margin: 15px;
  }
}

.youtube {
  width: 160px;
  text-align: center;
  background: #b00;
  border-radius: 5px;
  padding: 15px 10px;
  position: fixed;
  bottom: 10px;
  right: 10px;
}

.youtube a {
  color: #fff;
  text-transform: capitalize;
  letter-spacing: 1px;
  text-decoration: none;
}

.bell {
  cursor: pointer;
  all: unset;
  margin: 0 auto;
  display: block;
}

.bell-animation {
  transform-origin: 50% 4px;
  animation: 5s ease-in-out forwards ring;
}

.bell:hover {
  cursor: pointer;
}

@-webkit-keyframes ring {
  0% {
    -webkit-transform: rotateZ(0);
  }

  1% {
    -webkit-transform: rotateZ(30deg);
  }

  3% {
    -webkit-transform: rotateZ(-28deg);
  }

  5% {
    -webkit-transform: rotateZ(34deg);
  }

  7% {
    -webkit-transform: rotateZ(-32deg);
  }

  9% {
    -webkit-transform: rotateZ(30deg);
  }

  11% {
    -webkit-transform: rotateZ(-28deg);
  }

  13% {
    -webkit-transform: rotateZ(26deg);
  }

  15% {
    -webkit-transform: rotateZ(-24deg);
  }

  17% {
    -webkit-transform: rotateZ(22deg);
  }

  19% {
    -webkit-transform: rotateZ(-20deg);
  }

  21% {
    -webkit-transform: rotateZ(18deg);
  }

  23% {
    -webkit-transform: rotateZ(-16deg);
  }

  25% {
    -webkit-transform: rotateZ(14deg);
  }

  27% {
    -webkit-transform: rotateZ(-12deg);
  }

  29% {
    -webkit-transform: rotateZ(10deg);
  }

  31% {
    -webkit-transform: rotateZ(-8deg);
  }

  33% {
    -webkit-transform: rotateZ(6deg);
  }

  35% {
    -webkit-transform: rotateZ(-4deg);
  }

  37% {
    -webkit-transform: rotateZ(2deg);
  }

  39% {
    -webkit-transform: rotateZ(-1deg);
  }

  41% {
    -webkit-transform: rotateZ(1deg);
  }

  43% {
    -webkit-transform: rotateZ(0);
  }

  100% {
    -webkit-transform: rotateZ(0);
  }
}

@-moz-keyframes ring {
  0% {
    -moz-transform: rotate(0);
  }

  1% {
    -moz-transform: rotate(30deg);
  }

  3% {
    -moz-transform: rotate(-28deg);
  }

  5% {
    -moz-transform: rotate(34deg);
  }

  7% {
    -moz-transform: rotate(-32deg);
  }

  9% {
    -moz-transform: rotate(30deg);
  }

  11% {
    -moz-transform: rotate(-28deg);
  }

  13% {
    -moz-transform: rotate(26deg);
  }

  15% {
    -moz-transform: rotate(-24deg);
  }

  17% {
    -moz-transform: rotate(22deg);
  }

  19% {
    -moz-transform: rotate(-20deg);
  }

  21% {
    -moz-transform: rotate(18deg);
  }

  23% {
    -moz-transform: rotate(-16deg);
  }

  25% {
    -moz-transform: rotate(14deg);
  }

  27% {
    -moz-transform: rotate(-12deg);
  }

  29% {
    -moz-transform: rotate(10deg);
  }

  31% {
    -moz-transform: rotate(-8deg);
  }

  33% {
    -moz-transform: rotate(6deg);
  }

  35% {
    -moz-transform: rotate(-4deg);
  }

  37% {
    -moz-transform: rotate(2deg);
  }

  39% {
    -moz-transform: rotate(-1deg);
  }

  41% {
    -moz-transform: rotate(1deg);
  }

  43% {
    -moz-transform: rotate(0);
  }

  100% {
    -moz-transform: rotate(0);
  }
}

@keyframes ring {
  0% {
    transform: rotate(0);
  }

  1% {
    transform: rotate(30deg);
  }

  3% {
    transform: rotate(-28deg);
  }

  5% {
    transform: rotate(34deg);
  }

  7% {
    transform: rotate(-32deg);
  }

  9% {
    transform: rotate(30deg);
  }

  11% {
    transform: rotate(-28deg);
  }

  13% {
    transform: rotate(26deg);
  }

  15% {
    transform: rotate(-24deg);
  }

  17% {
    transform: rotate(22deg);
  }

  19% {
    transform: rotate(-20deg);
  }

  21% {
    transform: rotate(18deg);
  }

  23% {
    transform: rotate(-16deg);
  }

  25% {
    transform: rotate(14deg);
  }

  27% {
    transform: rotate(-12deg);
  }

  29% {
    transform: rotate(10deg);
  }

  31% {
    transform: rotate(-8deg);
  }

  33% {
    transform: rotate(6deg);
  }

  35% {
    transform: rotate(-4deg);
  }

  37% {
    transform: rotate(2deg);
  }

  39% {
    transform: rotate(-1deg);
  }

  41% {
    transform: rotate(1deg);
  }

  43% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(0);
  }
}

.pulse-button {
  cursor: pointer;
  border-radius: 50%;
  -webkit-animation: 2.5s infinite pulse;
}

@-webkit-keyframes pulse {
  0% {
    transform: scale(1);
  }

  70% {
    transform: scale(1);
  }

  100% {
    transform: scale(1);
  }
}



/*# sourceMappingURL=styles.756044ea.css.map */
