.linkSocialNetwork {
    width: 10%;
    text-align: center;
    cursor: pointer;
}

.ul-wrapper {
    display: flex;
    list-style: none;
    flex-wrap: wrap;
    justify-content: center;
    gap: 3px;
}

.wrapper-social-networker {
    display: flex;
    position: relative;
    justify-content: center;
    margin: 20px;
    width: 85%;
    align-items: center;
}

.wrapper-social-networker ul {
    list-style: none;
}

.wrapper-social-networker ul li {
    line-height: 0;
    margin: 0 10px;
    text-align: center;
    cursor: pointer;
    border-radius: 50%;
    border: 5px solid #d8e2dc;
    float: left;
    transition: all 0.5s ease;
    padding: 25px;
}

.wrapper-social-networker ul li .fa {
    color: #d8e2dc;
    transition: all 0.5s ease;
}

.wrapper-social-networker ul li:hover.linkedin {
    border: 5px solid #2867b2;
    box-shadow: 0 0 15px #2867b2;
    transition: all 0.5s ease;
}

.wrapper-social-networker ul li .fa-linkedin {
    color: #3b5998aa;
}

.wrapper-social-networker ul li:hover .fa-linkedin {
    color: #3b5998;
    text-shadow: 0 0 15px #2867b2;
    transition: all 0.5s ease;
}

.wrapper-social-networker ul li:hover.twitter {
    border: 5px solid #00aced;
    box-shadow: 0 0 15px #00aced;
    transition: all 0.5s ease;
}

.wrapper-social-networker ul li .fa-twitter {
    color: #00acedaa;
}

.wrapper-social-networker ul li:hover .fa-twitter {
    color: #00aced;
    text-shadow: 0 0 15px #00aced;
    transition: all 0.5s ease;
}

.wrapper-social-networker ul li:hover.github {
    border: 5px solid #333;
    box-shadow: 0 0 15px #333;
    transition: all 0.5s ease;
}

.wrapper-social-networker ul li .fa-github {
    color: #333333aa;
}

.wrapper-social-networker ul li:hover .fa-github {
    color: #333;
    text-shadow: 0 0 15px #333;
    transition: all 0.5s ease;
}

.wrapper-social-networker ul li:hover.envelope {
    border: 5px solid #4dc247;
    box-shadow: 0 0 15px #4dc247;
    transition: all 0.5s ease;
}

.wrapper-social-networker ul li .fa-envelope {
    color: #4dc247aa;
}

.wrapper-social-networker ul li:hover .fa-envelope {
    color: #4dc247;
    text-shadow: 0 0 15px #4dc247;
    transition: all 0.5s ease;
}

.wrapper-social-networker ul li:hover.rss {
    border: 5px solid #f26522;
    box-shadow: 0 0 15px #f26522;
    transition: all 0.5s ease;
}

.wrapper-social-networker ul li .fa-rss {
    color: #f26522aa;
}

.wrapper-social-networker ul li:hover .fa-rss {
    color: #f26522;
    text-shadow: 0 0 15px #f26522;
    transition: all 0.5s ease;
}

@media screen and (max-width: 640px) {
    .wrapper-social-networker {
        margin: 0;
    }

    .wrapper-social-networker ul li .fa {
        color: #d8e2dc;
        transition: all 0.5s ease;
    }
}

@media screen and (max-width: 340px) {
    .wrapper-social-networker {
        margin: 0px;
    }
    .wrapper-social-networker ul li {
        margin: 15px;
    }
}

.youtube {
    position: fixed;
    bottom: 10px;
    right: 10px;
    width: 160px;
    text-align: center;
    padding: 15px 10px;
    background: #bb0000;
    border-radius: 5px;
}

.youtube a {
    text-decoration: none;
    color: #fff;
    text-transform: capitalize;
    letter-spacing: 1px;
}
